import React from 'react';
import { reformatAnchorText } from '../helpers';

const Paragraph = (props) => {
  let { attributes } = props;
  let { data, className, innerContent } = attributes;

  const anchorRegex = /<a[^>]*?>[^<]*?<\/a>/;

  return anchorRegex.test(innerContent) ? (
    <p className={className} {...data}>
      {reformatAnchorText(innerContent)}
    </p>
  ) : (
    innerContent && (
      <p
        className={className}
        dangerouslySetInnerHTML={{ __html: innerContent }}
        {...data}
      />
    )
  );
};

export default Paragraph;
