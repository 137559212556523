import { isNonEmptyString } from './validation-helpers';

export const capitalizeFirstLetter = (str: string) => {
  if (!str) {
    return str;
  }

  const [firstChar, ...rest] = str.split('');
  const captialized = [firstChar.toUpperCase(), ...rest].join('');

  return captialized;
};

export const getNonEmptyStringOrFallback = (
  str: string,
  fallback: string = ''
) => {
  if (isNonEmptyString(str)) {
    return str;
  }

  return fallback;
};

export const normalizeUrlPath = (path: string) => {
  if (!isNonEmptyString(path)) {
    return '';
  }

  // lets auto-add a slash for them...
  if (path[0] !== '/') {
    path = `/${path}`;
  }

  // replace any accidental double slashes
  return path.replace(/\/\//g, '/');
};
