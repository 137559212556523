/**
 * DO NOT use tailwind classes as this is used for both RGB and Sava
 */
import React, { createRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import amplitude from 'amplitude-js';
import Cookies from 'universal-cookie';
import Link from './LinkHelper';
import { getXHoursFromNow } from '../../helpers/date-helpers';
import Image from '../../components/basic/Image';

const navItems = (items) => {
  return items.map((item, i) => {
    const { _path, title } = item;

    const isExternal = /http/.test(_path);

    let Tag = Link;
    if (!_path) {
      Tag = 'span';
    } else if (isExternal) {
      Tag = 'a';
    }

    if (_path) {
      var props = { href: _path, target: '_blank' };
    }

    return [
      <li className="footer__item" key={i}>
        <Tag {...props}>
          <span
            className="flex items-center"
            dangerouslySetInnerHTML={{ __html: title }}
          ></span>
        </Tag>
      </li>,
    ];
  });
};

const columns = (allColumns) => {
  const getColumnInner = (column) => {
    const { heading, items } = column;

    return [
      <div key={column}>
        <h4>
          <span>{heading}</span>
        </h4>
        <ul className="footer__menu">{navItems(items)}</ul>
      </div>,
    ];
  };

  return allColumns.map((column, idx) => {
    return (
      <div className="footer__column column" key={idx}>
        {getColumnInner(column)}
      </div>
    );
  });
};

const socialIcons = () => [
  <a
    href="https://www.facebook.com/ripplingapp/"
    key="si-fb"
    title="Like Rippling on Facebook"
    target="_blank"
    rel="noopener noreferrer"
  >
    <Image
      attributes={{
        mediaURL: 'https://rippling2.imgix.net/Button-1.svg',
        className: 's-footer-fb',
        nonResponsive: true,
      }}
    />
  </a>,
  <a
    href="https://www.linkedin.com/company/rippling"
    key="si-li"
    title="Follow Rippling on LinkedIn"
    target="_blank"
    rel="noopener noreferrer"
  >
    <Image
      attributes={{
        mediaURL: 'https://rippling2.imgix.net/Button-2.svg',
        className: 's-footer-lin',
        nonResponsive: true,
      }}
    />
  </a>,
  <a
    href="https://twitter.com/rippling"
    key="si-tw"
    title="Follow Rippling on Twitter"
    target="_blank"
    rel="noopener noreferrer"
  >
    <Image
      attributes={{
        mediaURL: 'https://rippling2.imgix.net/Button-4.svg',
        className: 's-footer-tw',
        nonResponsive: true,
      }}
    />
  </a>,
  <a
    href="https://www.instagram.com/ripplinghr"
    key="si-ig"
    title="Follow Rippling on Instagram"
    target="_blank"
    rel="noopener noreferrer"
  >
    <Image
      attributes={{
        mediaURL: 'https://rippling2.imgix.net/Button-5.svg',
        className: 's-footer-ig',
        nonResponsive: true,
      }}
    />
  </a>,
];

const Footer = (data) => {
  const { menu, options, blog } = data;
  const field = createRef();
  const router = useRouter();
  const strings = menu?.strings || {};

  if (options === 'No Footer') {
    return null;
  }

  const handleClick = () => {
    const pathname = window.location.pathname;
    const cookies = new Cookies();

    cookies.set('user_email', field.current.value, {
      path: '/',
      domain: window.Location.hostname,
      expires: getXHoursFromNow(1),
    });

    amplitude
      .getInstance()
      .logEvent('Click - Multistep Form', { URL: pathname });

    window.dataLayer.push({
      event: 'multistep_form_start',
      formCategory: 'simple',
      formType: 'footer',
    });

    let redirectTo = '/request-demo';
    if (['/peo', '/peo-02', '/peo-03'].includes(pathname)) {
      redirectTo = '/peo-request-demo';
    } else if (['/accountants', '/accountants-02'].includes(pathname)) {
      redirectTo = '/accountants-demo-request';
    } else if (['/brokers', '/brokers-02'].includes(pathname)) {
      redirectTo = '/brokers-demo-request';
    }

    router.push(redirectTo);
  };

  return (
    <footer className="footer">
      <div className="footer_top">
        <div className="grid footer__row row">
          <div className="footer__columns">{columns(menu.columns)}</div>
        </div>
        <div className="grid footer__bottom row">
          <div>
            <div className="form-blurb">
              <h1>{strings.readyToSee}</h1>
              <p>{strings.freeDemo}</p>
            </div>
            <div className="form-section">
              <div className="form-container">
                <input
                  className="field"
                  ref={field}
                  type="text"
                  placeholder={strings.enterYourEmail}
                />
                <a className="btn" onClick={handleClick}>
                  {strings.seeRippling}
                </a>
              </div>
              <div className="social">{socialIcons()}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__logo">
        <Image
          attributes={{
            mediaURL: 'https://rippling2.imgix.net/wordmark-1.svg',
            nonResponsive: true,
          }}
        />
        <p className="footer__item--legal is-style-overline">
          © 2024 Rippling People Center Inc.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
