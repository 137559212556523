import React from 'react';
import Image from 'next/image';

const ImageComponent = (props) => {
  let { attributes } = props;
  let {
    className,
    mediaURL,
    mediaAlt,
    width,
    height,
    layout,
    nonResponsive,
    preload,
  } = attributes;

  if (nonResponsive || process.env.NEXT_PUBLIC_IS_CIRCLECI) {
    return <img className={className} src={mediaURL} loading={'lazy'} />;
  } else {
    let layoutToUse = layout;

    if (!layout) {
      layoutToUse = !width ? 'fill' : 'responsive';
    }

    return (
      <Image
        className={className}
        src={mediaURL}
        alt={mediaAlt}
        layout={layoutToUse}
        width={width}
        height={height}
        priority={preload}
      />
    );
  }
};

export default ImageComponent;
