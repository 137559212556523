import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { getUrlParam } from '../components/helpers';
import { get30Days, getXHoursFromNow } from '../helpers/date-helpers';

import Head from './Head';

import Header from './partials/Header';
import Footer from './partials/Footer';


import PreviewModal from './partials/PreviewModal';

export default function Layout({
  head,
  header,
  footer,
  mode,
  children,
  meta,
  previewData,
}) {
  const { isDevelopment } = mode;

  useEffect(() => {
    const cookies = new Cookies();

    const urlCookies = [
      'utm_campaign',
      'utm_content',
      'utm_medium',
      'utm_source',
      'utm_term',
      'aclid',
      'gclid',
      'fbclid',
      'msclkid',
      'Product_Interest',
    ];
    const { hostname } = window.location;

    urlCookies.forEach(function (item) {
      const itemValue = getUrlParam(item);
      if (itemValue) {
        cookies.set(item, itemValue, {
          path: '/',
          domain: hostname,
          expires: get30Days(),
        });
      }
    });

    const isExistingCustomer = cookies.get('ExistingCustomer');
    const firstVisitTimeStampCookie = cookies.get('first_visit_timestamp');

    if (!firstVisitTimeStampCookie && !isExistingCustomer) {
      cookies.set('first_visit_timestamp', new Date().getTime(), {
        path: '/',
        domain: hostname,
        expires: getXHoursFromNow(1),
      });
    }
  }, []);

  useEffect(() => {
    if (meta?.page?.useDesignSystem2?.[0] === false) {
      document.querySelector('body').classList.add('-ds2');
    } else {
      document.querySelector('body').classList.remove('-ds2');
    }
  }, []);

  const cookies = new Cookies();
  const activeExperiments = cookies.get('rippling_experiments') || [];

  const isHidden = false;
  const experimentNames =
    (activeExperiments &&
      activeExperiments.map((experiment) => experiment.name).join(' ')) ||
    '';

  const { schema } = meta?.seo || {};

  return (
    <div className={`container ${isHidden ? 'hidden' : ''} ${experimentNames}`}>
      <Head head={head} />

      {!!schema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: schema }}
        />
      )}

      <Header
        {...header}
        locale={mode.locale}
        pageLocale={mode.pageLocale}
        publishedLocales={mode.publishedLocales}
        isMiniSite={mode.isMiniSite}
        pageLocaleDefaulted={mode.pageLocaleDefaulted}
      />

      <main>{children}</main>

      {isDevelopment && <PreviewModal previewData={previewData} />}

      <Footer {...footer} />
    </div>
  );
}
