import Image from '../components/basic/Image';
import { setGlobalOptions } from '../lib/globals';
import { checkIsDevelopment } from '../lib/_helpers';

const FLAGS = {
  US: 'https://rippling.imgix.net/images/US-4.svg',
  CA: 'https://rippling.imgix.net/images/CA-flag.svg',
  GB: 'https://rippling.imgix.net/images/GB-flag.svg',
  AU: 'https://rippling.imgix.net/images/AU-flag.svg',
  FR: 'https://rippling.imgix.net/images/FR-flag-1.svg',
  IE: 'https://rippling.imgix.net/images/IE-flag-1.svg',
  NL: 'https://rippling.imgix.net/images/netherlands.svg',
  DE: 'https://rippling.imgix.net/images/germany.svg',
  ES: 'https://rippling.imgix.net/images/kEp43M7o-spain.svg',
  IT: 'https://rippling.imgix.net/images/italy.svg',
  PT: 'https://rippling.imgix.net/images/portugal.svg',
};

export const getFlag = (country, additionalClasses) => (
  <Image
    attributes={{
      mediaURL: FLAGS[country],
      className: `w-24 h-24 rounded-50% object-cover ${
        additionalClasses || ''
      }`,
      nonResponsive: true,
    }}
  />
);

export const getFlagXS = (country) => (
  <Image
    attributes={{
      mediaURL: FLAGS[country],
      className: 'w-18 h-18 rounded-50% object-cover',
      nonResponsive: true,
    }}
  />
);

export const getCachedGlobals = (type, globals) => {
  const { header, footer, head } = setGlobalOptions({
    data: { globals },
    type,
    slug: '',
  });

  return {
    ...globals,
    header,
    footer,
    head: {
      ...head,
      mode: {
        isDevelopment: checkIsDevelopment(),
      },
    },
  };
};
