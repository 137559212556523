import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import loadable from '@loadable/component';
import { COLORS } from '../../tailwind/config';

const Icon = ({ attributes }) => {
  let { name, type, style, color, className } = attributes;

  let SVG = loadable(() => import(`../../assets/svg/${type}/${style}/${name}`));

  return <SVG iconClass={className} color={COLORS[color] || '#000'} />;
};

export default Icon;
