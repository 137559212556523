import React, { useState } from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import { useRouter } from 'next/router';

const PreviewModal = ({ previewData }) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  let hasSubmitted = false;

  return (
    <div
      className="fixed bottom-24 left-24 z-9000 group"
      style={{ minWidth: 300 }}
    >
      {isOpen ? (
        <div
          className="bg-white p-32 pb-8 rounded-2 mb-12"
          style={{ maxWidth: 300 }}
        >
          <Formik
            initialValues={{
              allLocales: false,
            }}
            validate={(values) => {
              const errors = {};

              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              const locale = values.allLocales ? '' : `/${router.locale}`;
              const path = `${locale}${router.asPath}`;

              await axios({
                url: `${window.location.origin}/api/www-vercel-revalidate`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: {
                  path,
                },
              });

              hasSubmitted = true;

              setSubmitting(false);
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form className="-bg-white" onSubmit={handleSubmit}>
                <p className="text-16 font-medium mb-8">
                  Refresh {router.asPath}
                </p>

                <div className="mb-16">
                  <div className="">
                    <input
                      id="rebuildOnReload"
                      className=""
                      type="checkbox"
                      name="allLocales"
                      checked={values.allLocales}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={true}
                    />
                    <label htmlFor="rebuildOnReload" className="mr-8">
                      <span>All locales</span>
                    </label>
                  </div>
                </div>
                <button
                  className="btn is-style-primary py-12 pl-32 pr-32"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Apply
                </button>
                <p
                  className={`mt-4 text-16 ${
                    isSubmitting || hasSubmitted ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  {!hasSubmitted
                    ? 'Revalidating...'
                    : 'Success! Refresh to see changes.'}
                </p>
              </form>
            )}
          </Formik>
        </div>
      ) : undefined}

      {!!previewData && (
        <div
          className="w-16 h-16 rounded-50% flex items-center justify-center bg-red"
          style={{ right: -30, top: 5 }}
        >
          !
        </div>
      )}

      <button
        className={`rounded-50% bg-plum w-32 h-32 cursor-pointer text-white flex items-center justify-center opacity-${
          isOpen ? '100' : '50'
        } group-hover:opacity-100`}
        onClick={() => setIsOpen(!isOpen)}
        style={{ fontSize: 36 }}
      >
        <span className="leading-0">{isOpen ? '×' : '+'}</span>
      </button>
    </div>
  );
};

export default PreviewModal;
